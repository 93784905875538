import React from "react";
import footerBg from '../assets/images/footer.jpg';
import footermobileBg from '../assets/images/footermobileBg.jpg';
import footerLogo from '../assets/images/footer_logo.gif';
import fakeEmployement from '../assets/images/fake-employement.jpg';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from "react-bootstrap";

import fb from '../assets/images/fb.png';
import tw from '../assets/images/tw.png';
import lin from '../assets/images/in.png';
import ig from '../assets/images/ig.png';
import yt from '../assets/images/yt.png';

import fb1 from '../assets/images/fb1.png';
import tw1 from '../assets/images/tw1.png';
import lin1 from '../assets/images/in1.png';
import ig1 from '../assets/images/ig1.png';
import yt1 from '../assets/images/yt1.png';

class Footer extends React.Component{
    render(){
        return(
            <footer>
                <img className="footerbgdesktop" src={footerBg} alt='' />
                <img className="footerbgmobile" src={footermobileBg} alt='' />
                <Container>
                    <div className="footer_bottom">
                        <Row>
                            <Col sm={4}>
                                <ul>
                                    <li><Link as={Link} to="/about-us">About Us</Link></li>
                                    <li><Link to="/investors-corner">Investor Relations</Link></li>
                                    <li><a href="https://nuvocohomeassist.com/" target="_blank">Nuvoco Home Assist</a></li>
                                </ul>
                            </Col>
                            <Col sm={4}>
                                <ul>
                                    <li><Link as={Link} to="/top-cement-company">Cement</Link></li>
                                    <li><Link as={Link} to="/ready-mix-concrete">Ready-Mix Concrete</Link></li>
                                    <li><Link as={Link} to="/modern-building-materials">Modern Building Materials</Link></li>
                                </ul>
                            </Col>
                            <Col sm={4}>
                                <ul>
                                    <li><Link to="/sustainability">Sustainability</Link></li>
                                    <li><Link to="/ready-mix-concrete#landmark">Landmark Projects</Link></li>
                                    <li><Link to="/life-at-nuvoco">Life At Nuvoco</Link></li>
                                </ul>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ span: 9, offset: 3 }} >
                                <div>
                                <Link to="life-at-nuvoco#fake-employment-disclaimer"><img  style={{borderRadius:'10px'}} src={fakeEmployement} /></Link>
                                </div>
                            </Col>
                        </Row> 
                        <div className="d-flex copyright">
                            <img src={footerLogo} alt='' />
                            <div>
                                <p>© 2023, Nuvoco Vistas Corp Ltd. All Rights reserved. <Link to="/disclaimer">Disclaimer.</Link> <a href="https://nuvoco.com/Final_RMX_Standard_Draft_3rd_Nov_2021.pdf" rel="noreferrer" target='_blank'><b><u>RMX Supply T&C.</u></b></a></p>
                                                                
                            </div>
                            <ul className="d-flex">
                                    <li><a href="https://www.facebook.com/Nuvoco" rel="noreferrer" target='_blank'><img className="in" src={fb} alt='' /><img className="out" src={fb1} alt='' /></a></li>
                                    <li><a href="https://twitter.com/nuvocovistas"  rel="noreferrer" target='_blank'><img className="in" src={tw} alt='' /><img className="out" src={tw1} alt='' /></a></li>
                                    <li><a href="https://in.linkedin.com/company/nuvocovistas"  rel="noreferrer" target='_blank'><img className="in" src={lin} alt='' /><img className="out" src={lin1} alt='' /></a></li>
                                    <li><a href="https://www.instagram.com/nuvocovistasofficial"  rel="noreferrer" target='_blank'><img className="in" src={ig} alt='' /><img className="out" src={ig1} alt='' /></a></li>
                                    <li><a href="https://www.youtube.com/NuvocoVistasCorpLtd"  rel="noreferrer" target='_blank'><img className="in" src={yt} alt='' /><img className="out" src={yt1} alt='' /></a></li>
                                </ul>
                        </div>
                        <div className="bis_web">
                            <p>The complete information on the licenses held by the manufacturer can be obtained from  <a href="https://www.bis.gov.in/" rel="noreferrer" target='_blank'><b><u>BIS website.</u></b></a></p>
                            <p><a href="https://smartodr.in/login" rel="noreferrer" target='_blank'><b><u>Online Dispute Resolution Portal.</u></b></a></p>
                            {/* <p><Link to="life-at-nuvoco#fake-employment-disclaimer"><b><u>Fake Employment Disclaimer</u></b></Link></p> */}
                        </div>
                        
                    </div>
                </Container>

                
             
            </footer>
        )
    }
}

export default Footer;