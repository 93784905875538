import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, Button, Form, Alert, Card } from 'react-bootstrap';
import banner from '../assets/images/brandstory.png';
import bannermobile from '../assets/images/brandstory-mobile.jpg';
import aboutBannerLogo from '../assets/images/socialIcon.png';
import StoryImg1 from '../assets/images/our-brand-story-img-1.png';
import StoryImg2 from '../assets/images/our-brand-story-img-2.jpg';
import slogodescription from '../assets/images/new-logo-description.jpg';
import BrandStoryBanner from '../assets/images/our-brand-story-banner.png';
import { Helmet } from 'react-helmet';

const BrandStory = () => {
  const [IsTab, setTabType] = useState(1);
  const setTab = (id) => {
    setTabType(id);
  }
  return (
    <div className="socialpage enviromentPage">


<Helmet>
    <title>Our Brand Story | Nuvoco Vistas</title>
    <meta name="keywords" description="" />
    <meta name="description" content="Nuvoco's Brand has acheived it's milestones since its inception shaping a new robust world with its wordclass cement and ready mix concrete quality" />
    <link rel="canonical" href="https://nuvoco.com/our-brand-story"/>
    </Helmet>

      <div className="banner text-center position-relative">
        <img className='desktopbannerinner' src={banner} alt="" />
        <img className='mobilebannerinner' src={bannermobile} alt="" />
      
        <div className='breadouter'>
          <div className='container'>
            <Breadcrumb className='mb-0 py-2'>
              <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>
                 Our Brand Story
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <div className='midpart'>
        <Container>
        <Row className=''>
          <h2 className='grad-heading'>Our Brand Story</h2>
          <p>
          The journey of Nuvoco is a story we feel is just the beginning. With its roots in India's most epic start-up in the 1970s – Nirma Group of Industries – Nuvoco has created its journey, reached its milestones, and built its way from humble beginnings in Nimbol to 11 Cement Manufacturing Facilities and more than 50 Ready-Mix Concrete Plants across the nation.
          </p>
          <p>In 2014, Hiren Patel, the son of Karsanbhai Patel, envisioned a company that would build the India of tomorrow. As a result, Nirma Group entered the cement business by building a greenfield cement plant in Nimbol, Rajasthan. The Nirma Group acquired Lafarge India Limited in 2016, thus marking its presence in India and showing a clear commitment to infrastructure in the country. In our journey toward growth, this was an important step.</p>
       </Row>
       <Row>
        <Col md={12}>
            <p>With this remarkable acquisition came a new identity that could match-up this scale. This challenge was then taken on with great vigour and enthusiasm by the leadership. Thus, Nuvoco was born. On 3rd April 2017, we named our Company <strong>‘Nuvoco Vistas Corporation Limited’</strong> which means a New Age Construction Company. ['Nuvo’ newness, ‘Co’ Construction and 'Vistas' – Avenues that predict and set trends for its industry.</p>
        </Col>
        {/* <Col md={12} className="text-center"><img src={StoryImg1} alt=""/></Col> */}
       </Row>
  </Container>
          <Container>
       <Row className="mt-5">
       <h2 className='grad-heading'>A Single Brand, A Unique Logo</h2>

        </Row>
        <Row className="mb-2">
        <Col md={6}  className="offset-md-3"><img src={StoryImg2} alt=""/></Col>
            <Col md={12} className="mb-4 mt-5">
            {/* <p>
<b>Font:</b> We selected a confident typeface that stands for the brand’s personality and attitude.<br /> Symbolises the long-term association and TRUST for the brand. 
</p>
                <p><b>Colour:</b> The Green colour convicts Freshness, Energy, Youthfulness, and Confidence. </p>
              
                <p><b>The Pyramid:</b> The pyramid is to portray the forward-looking value of this company.<br /> It connotes growth, innovation, and a future-driven vision.<br /> A company that looks ahead with the intention of making this world better than it was yesterday.</p>
                 <p><b>The V:</b> The V not only connotes a mark of trust and quality but also symbolizes the collaborative power of the organization.</p>
                 <p><b>The Roundel:</b> The roundel (two half circles) portrays care, protection, and trust.</p>
          
          <br /> */}
          <p>We redefined our organization with a mission to be a <strong>‘Leading Building Materials Company Delivering Superior Performance’</strong> and a vision of <strong>'Building a Safer, Smarter and Sustainable World'.</strong>
                    <ul>
                        <li><b>Safer:</b> Superior quality products that are safer for our customers and everyone associated with us.</li>
                        <li><b>Smarter:</b> Innovative, contemporary, and value-for-money solutions for our discerning customers.</li>
                        <li><b>Sustainable:</b> Building and contributing to a sustainable world by creating products that cause less damage to the environment.</li>
                    </ul>
                </p>
            </Col>
           
        </Row>
     
        <Row>
        <h2 className='grad-heading'>The Making of India’s 5<sup>th</sup> Largest Cement Group</h2>
        </Row>
        <Row>
        <Col md={12}>
            <p>Comes the year 2020, when everything was put to a halt by the COVID-19 Pandemic. Despite these uncertainties, the Company continued to grow organically and inorganically.  We acquired NU Vista Limited (formerly Emami Cement Limited) and became the fifth largest cement company in India and the largest cement company in the East in terms of capacity.</p>
        </Col>
      
       </Row>
       <Row className="mb-5">
        <Col md={12}>
            <p>This brought new synergies in both capacity and geography and in our brand identity.  NU Vista's Red signifies Energy and Passion, while the dual shades of Green signify Nuvoco’s collaborative approach, focusing on Innovation and sustainability. </p>
        <p><br></br>
        <Row>
        <Col md={6} >
        <img src={slogodescription} alt=""/>
        </Col>

        <Col md={6} >
        <iframe width="560" height="399" src="https://www.youtube.com/embed/BpntB2o4YlM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </Col>
        </Row>
</p>
<br></br>
     <p> <strong> Through the years, we strengthened our businesses – grown Cement capacities, reshaped Ready-Mix Concrete (RMX) and nurtured Modern Building Materials (MBM) while serving a customer base that’s unique to our industry.  </strong></p>
<p><strong>Today, with a capacity of 23.8 MMTPA, Nuvoco has become the leading player in the East and the 5th largest cement group in the country in terms of capacity offering a diverse product portfolio of over 50 products including our flagship brands Concreto, Double Bull and Duraguard. </strong></p>
<p>
    


        </p>
       


       
        </Col>
       </Row>
        </Container>
      </div>
    </div>
  )
}

export default BrandStory