import React, {useEffect} from "react";
import {Container, Button, Row, Col} from 'react-bootstrap';

import TopBanner from'../common/topbanner';
import AreaOfApplication from'../common/areaOfApplication';
import BenefitSlider from'../common/benefitSlider';

import GetRightForm from "../common/get-right";

import { productApi } from "../redux";
import { connect } from "react-redux";
import { imgUrl } from "../redux/config";
import { useLocation} from "react-router-dom";
import { Helmet } from "react-helmet";

const CementConcreto = ({productApi, product}) => {
	const location = useLocation();
    useEffect(() => {
        async function fetchData() {
            var x = await location.pathname.split("/");
            productApi(`${x[1]}/${x[2]}`);
        }

        fetchData();
    }, [location, productApi]);


    if (Object.keys(product).length) {
		var data = { ...product.data };
        var productDetails = JSON.parse(data.page);
        var broucher = JSON.parse(productDetails.broucher_langage);
        var broucherPdf = JSON.parse(productDetails.broucher_langage_file);
        console.log('Product Detail---' + (data.page));
        if(productDetails.page_url == "products/double-bull-procem-cement"){
            window.location.replace('https://nuvoco.com/')
        }
        if(productDetails.page_url == "products/duraguard-waterseal-cement"){
            window.location.replace('https://nuvoco.com/')
        }
	}
    return(
        <>
        {(Object.keys(product).length && (
               <div className="cement_concreto inner_page">
                <Helmet>
                  <title>{productDetails.meta_title}</title>
                  <meta name="keywords" description="" />
                  <meta name="description" content={productDetails.meta_description} />
                  <meta name="robots" content={productDetails.meta_robots} />
                  <link rel="canonical" href={productDetails.canonical_rel} />
                </Helmet>
               <TopBanner 
                   title={productDetails.desc_img_title} 
                   bannerImg={imgUrl + productDetails.desc_img}                   
               />
               <div className='midpart mt-5'>
                   <Container>
                       <h2 className='grad-heading'>{productDetails.desc_img_title}</h2>
                       <p>
                           {productDetails.desc_short}
                       </p>
   
                       <AreaOfApplication 
                            title={productDetails.area_of_application_title}
                            desc={productDetails.area_of_application_subtitle}
                            product = {productDetails}
                            imgUrl ={imgUrl}
                            img={productDetails.section1_img} 
                         />
                      
                       <BenefitSlider 
                            imgList={productDetails.benifits_img}
                            titleList={productDetails.benifits_img_title}
                            imgUrl ={imgUrl}
                        />
                        {productDetails.page_url == "products/duraguard-xtra-f2f" ? "" :
                        (
                        <>
                       <h2 className='grad-heading'>Brochures</h2>
                       <Row>
                            {broucher.map((item, index)=>{
                                return (
                                    <Col md={3} key={index}>
                                        <Button variant="success" target="_blank" className="btn_lg" href={imgUrl + broucherPdf[index]}>{item}</Button>
                                    </Col>
                                );
                            })}
                       </Row>   
                       </>   
                       )  
                       } 
                        <GetRightForm product={productDetails.page_url?.split("/")[1]}/>
                       {/* <GetRightForm/>                        */}
                   </Container>
               </div>
   
               
           </div>
         )) || ''}
         </>
    
        
    )   
}

const mapStatetoProps = (state) => {
	return {
		product: state.productR.product,
	};
};
const mapDispatchtoProps = (dispatch) => {
	return {
		productApi: function (page_url) {
			dispatch(productApi(page_url));
		},
	};
};

export default connect(mapStatetoProps, mapDispatchtoProps)(CementConcreto);