import React, { useEffect, useState } from "react";

import { mediaRelationApi, blogApi } from "../redux";
import { connect } from "react-redux";
import { imgUrl } from "../redux/config";

import {Breadcrumb, Container, Button, Row, Col} from 'react-bootstrap';
import banner from '../assets/images/media_banner.png'
import aboutBannerLogo from '../assets/images/mediaicon.png';

import Slider from "react-slick";

import BlogCard from '../common/blogCard';
import Iframe from 'react-iframe';
import { TwitterTimelineEmbed } from "react-twitter-embed";
import {Card} from 'react-bootstrap';
import { useLocation} from "react-router-dom";

const settings = {
    autoplay:true,
    autoplaySpeed: 2000,
    dots: false,
    arrow:true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint:480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
            },
        },
    ],
  };


let IsData = false;
const category = [
    {id: 1, category: 'Press Releases'},
    {id: 3, category: 'Authored Articles'},
    {id: 2, category: 'Key Interviews'},
    {id: 4, category: 'Blogs'}
]
let id = 1;
const Media = ({mediaRelationApi, media, blogApi, blog}) => {
    const location = useLocation();
    if(!IsData){
        var x = location.pathname.split("/");
        id = x[2] === 'blog' ? 4 : 1;
    }
  
    useEffect(() => {
        if(id ===1){
            mediaRelationApi(1, '', '');
        } else{
            blogApi();
        }
        
	}, [blogApi, mediaRelationApi]);
    
    if (Object.keys(media).length) {
        IsData = true;
        const mediaData = {...media.data};
        var mediaList = JSON.parse(mediaData.media);
    }
    if (Object.keys(blog).length && id === 4) {
        IsData = true;
        const blogData = {...blog.data};
        mediaList = blogData.Blogs;
    }
    const setCategory = (item) =>{
        console.log(item)
        id = item.id;
        if(id <= 3){
            mediaRelationApi(item.id, '', '');
        } else{
            blogApi();
        }
        
    }
    return(
        <div className="media inner_page">
           <div className="banner text-center position-relative">
                <img className='img_round' src={banner} alt="" />
                <article className='position-absolute text-center'>
                    <span className='d-flex justify-content-center align-items-center mx-auto'><img src={aboutBannerLogo} alt="" /></span>
                    <h1 className="text-uppercase">Media</h1>
                </article>
                <div className='breadouter'>
                    <div className='container'>
                        <Breadcrumb className='mb-0 py-2'>
                            <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                Media
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>
           {(IsData && (
            <section>
                <Container>
                    <div className="text-center">
                        {category.map((data, i) =>{
                            return <Button key={i} variant="success" className={id === data.id ? 'btn_lg active' : 'btn_lg'} onClick={()=> setCategory(data)}>{data.category}</Button>
                        })}
                    </div>

                    <div className="blog text-center card_round wow fadeInUp" data-wow-delay="0.5s">
                        {id <=3 ? 
                            <Slider {...settings}>
                                {mediaList.map((slide, index) =>{
                                    return (
                                        <div key={index} className="wow fadeInUp" data-wow-delay="0.5s">
                                            <BlogCard item={slide} id={id} uri ={imgUrl} />
                                        </div>
                                    );
                                })}                                                       
                            </Slider>
                             :                             
                             <Row>
                                {mediaList.map((slide, index) =>{
                                    return (
                                            <Col key={index} md={4} className='bl_list'>
                                                <BlogCard item={slide} id={id} uri ={imgUrl} />
                                            </Col>
                                        );
                                })}
                            </Row>
                        }
                        
                    </div> 
                </Container>
            </section>
            )) || ''}

            <section className="card_round pt-5">
                    <Container>
                    <h2 className='grad-heading pd-5 mb-5'>Social Feed</h2>
                        <div className="socFeed">
                            
                            <Row>
                                <Col md={6} className='mb-2'>
                                    <Card>
                                        <Card.Body>
                                            <Iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FNuvoco&tabs=timeline&width=1000&height=600&small_header=false&adapt_container_width=false&hide_cover=false&show_facepile=false&appId"
                                            width="500" height="600" scrolling="no"
                                            frameborder="0" allowfullscreen="true"
                                            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></Iframe>
                                        </Card.Body>
                                    </Card>
                                
                                </Col>
                                <Col>                            
                                    <Card>
                                        <Card.Body>
                                            <TwitterTimelineEmbed
                                            sourceType="profile"
                                            screenName="NuvocoVistas"
                                            options={{ height: 600, width: '500' }}
                                            />
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Container>
            </section>
        </div>
        
    )
}

const mapStatetoProps = (state) => {
	return {
		media: state.mediaR.media,
        blog: state.mediaR.blog
	};
};
const mapDispatchtoProps = (dispatch) => {
	return {
		mediaRelationApi: function (category_id, month_id, year_id) {
			dispatch(mediaRelationApi(category_id, month_id, year_id));
		},
		blogApi: function () {
			dispatch(blogApi());
		},
	};
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Media);
